// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EpvlB{position:relative;min-height:190px;background:#fff;overflow-x:hidden}.EpvlB.U4joE{border:1px solid #e2e2e2;background:#fff;border-radius:8px}.adbZJ.EpvlB{background:#181716}._7SnG1{display:flex;align-items:flex-end;justify-content:space-between;padding:24px 8px 0}@media screen and (min-width:400px){._7SnG1{padding:24px 16px 0}}.U4joE ._7SnG1{padding-right:16px;padding-left:16px}._5jNRX ._7SnG1{align-items:center;padding-top:20px;padding-right:8px}.z18AB{padding-right:40px;margin:0;color:#2d2d2d;font-family:\"Merriweather\",serif;font-size:26px;font-weight:700;line-height:30px}.U4joE .z18AB{font-family:\"Source Sans Pro\",sans-serif;font-size:20px;line-height:24px}.adbZJ .z18AB{color:#fff}.sAhMK{flex:0 0 auto}.sAhMK.\\-10fP>svg{transform:rotate(-90deg)}.OiqSx{display:flex;padding:24px 0 32px;margin:0;list-style:none;overflow-x:auto;overflow-y:hidden;-ms-scroll-snap-type:x mandatory;scroll-snap-type:x mandatory}.LoY0z .OiqSx{padding-top:32px}.VWysQ .OiqSx{overflow:auto;flex-flow:column;grid-gap:var(--item-gap);gap:var(--item-gap)}.U4joE .OiqSx{padding:16px 0 24px}._5jNRX .OiqSx{padding-top:12px}.tPNkT{display:flex;width:calc(100% - var(--item-gap)*4);max-width:var(--item-width);box-sizing:content-box;flex-shrink:0;scroll-snap-align:start}.tPNkT,.tPNkT:first-child{padding-left:var(--item-gap)}@media screen and (min-width:400px){.tPNkT:first-child{padding-left:calc(var(--item-gap) + 8px)}}.tPNkT:last-child{padding-right:var(--item-gap)}@media screen and (min-width:400px){.tPNkT:last-child{padding-right:calc(var(--item-gap) + 8px)}}@media screen and (min-width:375px){.tPNkT{width:var(--item-width)}}.VWysQ .tPNkT{width:unset;max-width:unset;height:unset;padding-right:var(--item-gap)}.lVXHo{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderMobile": "EpvlB",
	"card-video": "U4joE",
	"dark": "adbZJ",
	"header": "_7SnG1",
	"withIcon": "_5jNRX",
	"title": "z18AB",
	"button": "sAhMK",
	"chevron-2": "-10fP",
	"list": "OiqSx",
	"withoutHeader": "LoY0z",
	"column": "VWysQ",
	"item": "tPNkT",
	"loader": "lVXHo"
};
module.exports = ___CSS_LOADER_EXPORT___;
