import { computed, defineComponent, provide, useCssModule } from '@nuxtjs/composition-api';
import { DEFAULT_OPTIONS } from '.';
import { getArticleInfo } from '~/utils';
import { PODCASTS_PARTNERS } from '~/components/PodcastPartner';
export default defineComponent({
    name: 'SliderMobile',
    props: {
        data: {
            default: () => [],
            type: Array,
        },
        options: {
            default: () => ({}),
            type: Object,
        },
        size: {
            required: true,
            type: Object,
        },
    },
    setup(props, { root }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        /**
         * Current options.
         */
        const currentOptions = computed(() => {
            return {
                ...DEFAULT_OPTIONS,
                ...props.options,
            };
        });
        /**
         * Provides options
         */
        provide('sliderMobileOptions', currentOptions.value);
        /**
         * Formatted items
         */
        const items = computed(() => {
            if (!(props.data || []).length) {
                return [];
            }
            const items = props.data?.slice(0, currentOptions.value.length) || [];
            return items.map((item) => {
                const data = item.data || item;
                const { podcast_file: file, photo, photo_present: photoPresent, serial, title } = data;
                const additionalInfo = getArticleInfo.call(root, data);
                let { path } = additionalInfo || {};
                if (!path.includes('utm_')) {
                    path = `${path}?utm_source=forbes&utm_campaign=${currentOptions.value.slug}`;
                }
                return {
                    photo,
                    photo_present: photoPresent,
                    title,
                    ...additionalInfo,
                    path,
                    ...(file && { file }),
                    ...(serial && { serial }),
                    ...(serial && { isPartner: serial.url in PODCASTS_PARTNERS() }),
                };
            });
        });
        /**
         * Current header options
         */
        const currentHeaderOptions = computed(() => {
            let bind = {};
            let component = 'p';
            const { titleTo } = props.options;
            if (titleTo) {
                const isExternalLink = !titleTo?.startsWith('/');
                if (isExternalLink) {
                    component = 'a';
                    bind = {
                        href: titleTo,
                        rel: 'noreferer nofollow noopener',
                        target: '_blank',
                    };
                }
                else {
                    component = 'NLink';
                    bind = {
                        to: titleTo,
                    };
                }
            }
            return {
                bind,
                component,
            };
        });
        /**
         * Current more options
         */
        const currentMoreOptions = computed(() => {
            const { moreIcon, moreTo, moreText } = currentOptions.value;
            if (!moreTo) {
                return {};
            }
            let bind = {};
            let click = () => {
                //
            };
            let component = '';
            let text = '';
            const methods = {
                showWaterfall() {
                    const event = new CustomEvent('togglePullout', {
                        detail: 'waterfall',
                    });
                    window.dispatchEvent(event);
                },
            };
            if (moreTo.startsWith('javascript:')) {
                bind = {
                    tag: 'button',
                };
                const methodName = moreTo.replace('javascript:', '');
                click = methods[methodName];
            }
            else if (moreTo.startsWith('http') || moreTo.startsWith('//')) {
                bind = {
                    href: moreTo,
                    tag: 'a',
                    target: '_blank',
                };
            }
            else {
                bind = {
                    tag: 'NLink',
                    to: moreTo,
                };
            }
            if (moreIcon) {
                bind.name = moreIcon;
                component = 'IconButton';
            }
            else {
                component = 'ReadMore';
                text = moreText || '';
            }
            return {
                bind,
                click,
                component,
                text,
            };
        });
        /**
         * Slide Components
         */
        const slideComponents = {
            brandvoice: () => import('~/components/SliderMobileSlideBrandvoice/SliderMobileSlideBrandvoice.vue'),
            'card-video': () => import('~/components/SliderMobileSlideCardVideo/SliderMobileSlideCardVideo.vue'),
            default: () => import('~/components/SliderMobileSlide/SliderMobileSlide.vue'),
            opinion: () => import('~/components/SliderMobileSlideOpinion/SliderMobileSlideOpinion.vue'),
            podcast: () => import('~/components/SliderMobileSlidePodcast/SliderMobileSlidePodcast.vue'),
            video: () => import('~/components/SliderMobileSlideVideo/SliderMobileSlideVideo.vue'),
        };
        return {
            css,
            currentHeaderOptions,
            currentMoreOptions,
            currentOptions,
            items,
            slideComponents,
        };
    },
});
